import React, {useContext} from "react";
import "./Skills.scss";
// import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import {illustration, skillsSection} from "../../portfolio";
import {Fade} from "react-reveal";
import codingPerson from "../../assets/lottie/codingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Skills() {
  const {isDark} = useContext(StyleContext);
  if (!skillsSection.display) {
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={codingPerson} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/developerActivity.svg")}
              ></img>
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1
              className={isDark ? "dark-mode skills-heading" : "skills-heading"}
            >
              {skillsSection.title}{" "}
            </h1>
            {skillsSection.subSections.map((subSection, index) => (
              <div key={index} className="skills-subsection">
                <h2 className="skills-subsection-title">{subSection.title}</h2>
                {subSection.company && (
                  <p className="skills-company">
                    <a href={subSection.company.url} target="_blank" rel="noopener noreferrer">
                      {subSection.company.name}
                    </a>
                  </p>
                )}
                <ul className="skills-list">
                  {subSection.skills.map((skill, skillIndex) => (
                    <li
                      key={skillIndex}
                      className={
                        isDark
                          ? "dark-mode subTitle skills-text"
                          : "subTitle skills-text"
                      }
                    >
                      {skill}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </div>
  );
}
